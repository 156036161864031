.personality-casual .squiggle {
  filter: url("#squiggle");
  position: relative;
  transform: translateZ(0);
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.aspect-ratio-none {
  padding-bottom: 0;
}

.aspect-ratio-square {
  padding-bottom: 100%;
}

.aspect-ratio-16\/9 {
  padding-bottom: 56.25%;
}

.aspect-ratio-4\/3 {
  padding-bottom: 75%;
}

.aspect-ratio-21\/9 {
  padding-bottom: 42.86%;
}

[x-cloak] {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #64748b;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

@keyframes reveal-card {
  0% {
    opacity: 0;
    translate: 0 100px;
  }

  50% {
    opacity: 0.1;
  }

  to {
    opacity: 1;
    translate: 0 0;
  }
}

@keyframes reveal-tr {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.animate-card {
  view-timeline-name: --reveling-card;
  view-timeline-axis: block;

  animation: linear reveal-card both;
  animation-timeline: --reveling-card;

  animation-range: entry 5% cover 30%;
}

.animate-link {
  view-timeline-name: --reveling-link;
  view-timeline-axis: block;

  animation: linear reveal-card both;
  animation-timeline: --reveling-link;

  animation-range: entry 5% cover 20%;
}

.animate-tr {
  view-timeline-name: --reveling-tr;
  view-timeline-axis: block;

  animation: linear reveal-tr both;
  animation-timeline: --reveling-tr;

  animation-range: entry 5% cover 10%;
}
